<template>
  <UiPopup
    v-model="modelValue"
    :title="currentFilter.name"
    primary-button-text="Add"
    secondary-button-text="Cancel"
    size="small"
    full-height
    align-title-left
    @confirm="submit"
  >
    <div class="my-2 flex flex-col gap-2">
      <UiInputSearch v-model="search" @search="search = $event" />
      <div
        v-for="item in currentFilter?.items
          ?.filter((i) => i.text.toLowerCase().includes(search.toLowerCase()))
          .sort((a, b) => localFilter.includes(b.value) - localFilter.includes(a.value))"
        :key="item.value"
        class="mt-2 flex flex-col px-3"
      >
        <UiInputCheckbox v-model="localFilter" :name="item.text" :value="item.value" :label="item.text" />
      </div>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import type { DynamicFilter, Filters } from '~/types'

const emits = defineEmits(['update:modelValue', 'input'])

const modelValue = defineModel<boolean>({ required: true })

type Props = {
  filterKey: string
  filters: Filters
  currentFilter: DynamicFilter
}

const props = withDefaults(defineProps<Props>(), {})

const search = ref('')
const localFilter = ref(props.filters[props.filterKey])

const submit = () => {
  emits('input', localFilter.value)
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
